import React, { Component } from 'react';
import './post-status-filter.css';
//import { Button } from 'reactstrap';
export default class PostStatusFilter  extends Component {
    constructor(props) {
        super(props);
        this.buttons = [
            {name: 'all', label: 'Все'},
            {name: 'like', label: 'Понравилось'},
        ]
    }
    render(){
        const buttons = this.buttons.map(({name, label}) => {
            const {filter, onFilterSelect} = this.props;
            const active = filter === name;
            const clazz = active ? 'btn-info' : 'btn-outline-secondary'
            return(
                 <button 
                 key={name} 
                 type='button' 
                 className={`btn ${clazz}`}
                 onClick={() => onFilterSelect(name)}>{label}</button>
               // <Button key={name} type='button' color='info' className={`btn ${clazz}`}>{label}</Button>
            )
        })
        return (
            <div className="btn-group">
                {buttons}
                
            </div>
        ) 
    }

}
